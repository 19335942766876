var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
/**
 * Tagify (v 4.19.0) - tags input component
 * By undefined
 * https://github.com/yairEO/tagify
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 * 
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 * 
 * THE SOFTWARE IS NOT PERMISSIBLE TO BE SOLD.
 */

!function (t, e) {
  exports = e();
}(exports, function () {
  "use strict";

  function t(t, e) {
    var i = Object.keys(t);
    if (Object.getOwnPropertySymbols) {
      var s = Object.getOwnPropertySymbols(t);
      e && (s = s.filter(function (e) {
        return Object.getOwnPropertyDescriptor(t, e).enumerable;
      })), i.push.apply(i, s);
    }
    return i;
  }
  function e(e) {
    for (var s = 1; s < arguments.length; s++) {
      var a = null != arguments[s] ? arguments[s] : {};
      s % 2 ? t(Object(a), !0).forEach(function (t) {
        i(e, t, a[t]);
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(a)) : t(Object(a)).forEach(function (t) {
        Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(a, t));
      });
    }
    return e;
  }
  function i(t, e, i) {
    return (e = function (t) {
      var e = function (t, e) {
        if ("object" != typeof t || null === t) return t;
        var i = t[Symbol.toPrimitive];
        if (void 0 !== i) {
          var s = i.call(t, e || "default");
          if ("object" != typeof s) return s;
          throw new TypeError("@@toPrimitive must return a primitive value.");
        }
        return ("string" === e ? String : Number)(t);
      }(t, "string");
      return "symbol" == typeof e ? e : String(e);
    }(e)) in t ? Object.defineProperty(t, e, {
      value: i,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : t[e] = i, t;
  }
  const s = (t, e, i, s) => (t = "" + t, e = "" + e, s && (t = t.trim(), e = e.trim()), i ? t == e : t.toLowerCase() == e.toLowerCase()),
    a = (t, e) => t && Array.isArray(t) && t.map(t => n(t, e));
  function n(t, e) {
    var i,
      s = {};
    for (i in t) e.indexOf(i) < 0 && (s[i] = t[i]);
    return s;
  }
  function o(t) {
    var e = document.createElement("div");
    return t.replace(/\&#?[0-9a-z]+;/gi, function (t) {
      return e.innerHTML = t, e.innerText;
    });
  }
  function r(t) {
    return new DOMParser().parseFromString(t.trim(), "text/html").body.firstElementChild;
  }
  function l(t, e) {
    for (e = e || "previous"; t = t[e + "Sibling"];) if (3 == t.nodeType) return t;
  }
  function d(t) {
    return "string" == typeof t ? t.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/`|'/g, "&#039;") : t;
  }
  function h(t) {
    var e = Object.prototype.toString.call(t).split(" ")[1].slice(0, -1);
    return t === Object(t) && "Array" != e && "Function" != e && "RegExp" != e && "HTMLUnknownElement" != e;
  }
  function g(t, e, i) {
    function s(t, e) {
      for (var i in e) if (e.hasOwnProperty(i)) {
        if (h(e[i])) {
          h(t[i]) ? s(t[i], e[i]) : t[i] = Object.assign({}, e[i]);
          continue;
        }
        if (Array.isArray(e[i])) {
          t[i] = Object.assign([], e[i]);
          continue;
        }
        t[i] = e[i];
      }
    }
    return t instanceof Object || (t = {}), s(t, e), i && s(t, i), t;
  }
  function p() {
    const t = [],
      e = {};
    for (let i of arguments) for (let s of i) h(s) ? e[s.value] || (t.push(s), e[s.value] = 1) : t.includes(s) || t.push(s);
    return t;
  }
  function c(t) {
    return String.prototype.normalize ? "string" == typeof t ? t.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : void 0 : t;
  }
  var u = () => /(?=.*chrome)(?=.*android)/i.test(navigator.userAgent);
  function m() {
    return ([10000000] + -1000 + -4000 + -8000 + -100000000000).replace(/[018]/g, t => (t ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> t / 4).toString(16));
  }
  function v(t) {
    return t && t.classList && t.classList.contains((this || _global).settings.classNames.tag);
  }
  function f(t, e) {
    var i = window.getSelection();
    return e = e || i.getRangeAt(0), "string" == typeof t && (t = document.createTextNode(t)), e && (e.deleteContents(), e.insertNode(t)), t;
  }
  function T(t, e, i) {
    return t ? (e && (t.__tagifyTagData = i ? e : g({}, t.__tagifyTagData || {}, e)), t.__tagifyTagData) : (console.warn("tag element doesn't exist", t, e), e);
  }
  function w(t) {
    if (t && t.parentNode) {
      var e = t,
        i = window.getSelection(),
        s = i.getRangeAt(0);
      i.rangeCount && (s.setStartAfter(e), s.collapse(!0), i.removeAllRanges(), i.addRange(s));
    }
  }
  function b(t, e) {
    t.forEach(t => {
      if (T(t.previousSibling) || !t.previousSibling) {
        var i = document.createTextNode("\u200B");
        t.before(i), e && w(i);
      }
    });
  }
  var y = {
    delimiters: ",",
    pattern: null,
    tagTextProp: "value",
    maxTags: 1 / 0,
    callbacks: {},
    addTagOnBlur: !0,
    addTagOn: ["blur", "tab", "enter"],
    onChangeAfterBlur: !0,
    duplicates: !1,
    whitelist: [],
    blacklist: [],
    enforceWhitelist: !1,
    userInput: !0,
    keepInvalidTags: !1,
    createInvalidTags: !0,
    mixTagsAllowedAfter: /,|\.|\:|\s/,
    mixTagsInterpolator: ["[[", "]]"],
    backspace: !0,
    skipInvalid: !1,
    pasteAsTags: !0,
    editTags: {
      clicks: 2,
      keepInvalid: !0
    },
    transformTag: () => {},
    trim: !0,
    a11y: {
      focusableTags: !1
    },
    mixMode: {
      insertAfterTag: "\xA0"
    },
    autoComplete: {
      enabled: !0,
      rightKey: !1,
      tabKey: !1
    },
    classNames: {
      namespace: "tagify",
      mixMode: "tagify--mix",
      selectMode: "tagify--select",
      input: "tagify__input",
      focus: "tagify--focus",
      tagNoAnimation: "tagify--noAnim",
      tagInvalid: "tagify--invalid",
      tagNotAllowed: "tagify--notAllowed",
      scopeLoading: "tagify--loading",
      hasMaxTags: "tagify--hasMaxTags",
      hasNoTags: "tagify--noTags",
      empty: "tagify--empty",
      inputInvalid: "tagify__input--invalid",
      dropdown: "tagify__dropdown",
      dropdownWrapper: "tagify__dropdown__wrapper",
      dropdownHeader: "tagify__dropdown__header",
      dropdownFooter: "tagify__dropdown__footer",
      dropdownItem: "tagify__dropdown__item",
      dropdownItemActive: "tagify__dropdown__item--active",
      dropdownItemHidden: "tagify__dropdown__item--hidden",
      dropdownInital: "tagify__dropdown--initial",
      tag: "tagify__tag",
      tagText: "tagify__tag-text",
      tagX: "tagify__tag__removeBtn",
      tagLoading: "tagify__tag--loading",
      tagEditing: "tagify__tag--editable",
      tagFlash: "tagify__tag--flash",
      tagHide: "tagify__tag--hide"
    },
    dropdown: {
      classname: "",
      enabled: 2,
      maxItems: 10,
      searchKeys: ["value", "searchBy"],
      fuzzySearch: !0,
      caseSensitive: !1,
      accentedSearch: !0,
      includeSelectedTags: !1,
      escapeHTML: !0,
      highlightFirst: !1,
      closeOnSelect: !0,
      clearOnSelect: !0,
      position: "all",
      appendTarget: null
    },
    hooks: {
      beforeRemoveTag: () => Promise.resolve(),
      beforePaste: () => Promise.resolve(),
      suggestionClick: () => Promise.resolve(),
      beforeKeyDown: () => Promise.resolve()
    }
  };
  function x() {
    (this || _global).dropdown = {};
    for (let t in (this || _global)._dropdown) (this || _global).dropdown[t] = "function" == typeof (this || _global)._dropdown[t] ? (this || _global)._dropdown[t].bind(this || _global) : (this || _global)._dropdown[t];
    (this || _global).dropdown.refs();
  }
  var O = {
    refs() {
      (this || _global).DOM.dropdown = this.parseTemplate("dropdown", [(this || _global).settings]), (this || _global).DOM.dropdown.content = (this || _global).DOM.dropdown.querySelector("[data-selector='tagify-suggestions-wrapper']");
    },
    getHeaderRef() {
      return (this || _global).DOM.dropdown.querySelector("[data-selector='tagify-suggestions-header']");
    },
    getFooterRef() {
      return (this || _global).DOM.dropdown.querySelector("[data-selector='tagify-suggestions-footer']");
    },
    getAllSuggestionsRefs() {
      return [...(this || _global).DOM.dropdown.content.querySelectorAll((this || _global).settings.classNames.dropdownItemSelector)];
    },
    show(t) {
      var e,
        i,
        a,
        n = (this || _global).settings,
        o = "mix" == n.mode && !n.enforceWhitelist,
        r = !n.whitelist || !n.whitelist.length,
        l = "manual" == n.dropdown.position;
      if (t = void 0 === t ? (this || _global).state.inputText : t, !(r && !o && !n.templates.dropdownItemNoMatch || !1 === n.dropdown.enable || (this || _global).state.isLoading || (this || _global).settings.readonly)) {
        if (clearTimeout((this || _global).dropdownHide__bindEventsTimeout), (this || _global).suggestedListItems = (this || _global).dropdown.filterListItems(t), t && !(this || _global).suggestedListItems.length && (this.trigger("dropdown:noMatch", t), n.templates.dropdownItemNoMatch && (a = n.templates.dropdownItemNoMatch.call(this || _global, {
          value: t
        }))), !a) {
          if ((this || _global).suggestedListItems.length) t && o && !(this || _global).state.editing.scope && !s((this || _global).suggestedListItems[0].value, t) && (this || _global).suggestedListItems.unshift({
            value: t
          });else {
            if (!t || !o || (this || _global).state.editing.scope) return (this || _global).input.autocomplete.suggest.call(this || _global), void (this || _global).dropdown.hide();
            (this || _global).suggestedListItems = [{
              value: t
            }];
          }
          i = "" + (h(e = (this || _global).suggestedListItems[0]) ? e.value : e), n.autoComplete && i && 0 == i.indexOf(t) && (this || _global).input.autocomplete.suggest.call(this || _global, e);
        }
        (this || _global).dropdown.fill(a), n.dropdown.highlightFirst && (this || _global).dropdown.highlightOption((this || _global).DOM.dropdown.content.querySelector(n.classNames.dropdownItemSelector)), (this || _global).state.dropdown.visible || setTimeout((this || _global).dropdown.events.binding.bind(this || _global)), (this || _global).state.dropdown.visible = t || !0, (this || _global).state.dropdown.query = t, this.setStateSelection(), l || setTimeout(() => {
          (this || _global).dropdown.position(), (this || _global).dropdown.render();
        }), setTimeout(() => {
          this.trigger("dropdown:show", (this || _global).DOM.dropdown);
        });
      }
    },
    hide(t) {
      var e = (this || _global).DOM,
        i = e.scope,
        s = e.dropdown,
        a = "manual" == (this || _global).settings.dropdown.position && !t;
      if (s && document.body.contains(s) && !a) return window.removeEventListener("resize", (this || _global).dropdown.position), (this || _global).dropdown.events.binding.call(this || _global, !1), i.setAttribute("aria-expanded", !1), s.parentNode.removeChild(s), setTimeout(() => {
        (this || _global).state.dropdown.visible = !1;
      }, 100), (this || _global).state.dropdown.query = (this || _global).state.ddItemData = (this || _global).state.ddItemElm = (this || _global).state.selection = null, (this || _global).state.tag && (this || _global).state.tag.value.length && ((this || _global).state.flaggedTags[(this || _global).state.tag.baseOffset] = (this || _global).state.tag), this.trigger("dropdown:hide", s), this || _global;
    },
    toggle(t) {
      (this || _global).dropdown[(this || _global).state.dropdown.visible && !t ? "hide" : "show"]();
    },
    getAppendTarget() {
      var t = (this || _global).settings.dropdown;
      return "function" == typeof t.appendTarget ? t.appendTarget() : t.appendTarget;
    },
    render() {
      var t,
        e,
        i,
        s = (t = (this || _global).DOM.dropdown, (i = t.cloneNode(!0)).style.cssText = "position:fixed; top:-9999px; opacity:0", document.body.appendChild(i), e = i.clientHeight, i.parentNode.removeChild(i), e),
        a = (this || _global).settings,
        n = "number" == typeof a.dropdown.enabled && a.dropdown.enabled >= 0,
        o = (this || _global).dropdown.getAppendTarget();
      return n ? ((this || _global).DOM.scope.setAttribute("aria-expanded", !0), document.body.contains((this || _global).DOM.dropdown) || ((this || _global).DOM.dropdown.classList.add(a.classNames.dropdownInital), (this || _global).dropdown.position(s), o.appendChild((this || _global).DOM.dropdown), setTimeout(() => (this || _global).DOM.dropdown.classList.remove(a.classNames.dropdownInital))), this || _global) : this || _global;
    },
    fill(t) {
      t = "string" == typeof t ? t : (this || _global).dropdown.createListHTML(t || (this || _global).suggestedListItems);
      var e,
        i = (this || _global).settings.templates.dropdownContent.call(this || _global, t);
      (this || _global).DOM.dropdown.content.innerHTML = (e = i) ? e.replace(/\>[\r\n ]+\</g, "><").split(/>\s+</).join("><").trim() : "";
    },
    fillHeaderFooter() {
      var t = (this || _global).dropdown.filterListItems((this || _global).state.dropdown.query),
        e = this.parseTemplate("dropdownHeader", [t]),
        i = this.parseTemplate("dropdownFooter", [t]),
        s = (this || _global).dropdown.getHeaderRef(),
        a = (this || _global).dropdown.getFooterRef();
      e && s?.parentNode.replaceChild(e, s), i && a?.parentNode.replaceChild(i, a);
    },
    refilter(t) {
      t = t || (this || _global).state.dropdown.query || "", (this || _global).suggestedListItems = (this || _global).dropdown.filterListItems(t), (this || _global).dropdown.fill(), (this || _global).suggestedListItems.length || (this || _global).dropdown.hide(), this.trigger("dropdown:updated", (this || _global).DOM.dropdown);
    },
    position(t) {
      var e = (this || _global).settings.dropdown,
        i = (this || _global).dropdown.getAppendTarget();
      if ("manual" != e.position && i) {
        var s,
          a,
          n,
          o,
          r,
          l,
          d,
          h,
          g,
          p = (this || _global).DOM.dropdown,
          c = e.RTL,
          u = i === document.body,
          m = i === (this || _global).DOM.scope,
          v = u ? window.pageYOffset : i.scrollTop,
          f = document.fullscreenElement || document.webkitFullscreenElement || document.documentElement,
          T = f.clientHeight,
          w = Math.max(f.clientWidth || 0, window.innerWidth || 0) > 480 ? e.position : "all",
          b = (this || _global).DOM["input" == w ? "input" : "scope"];
        if (t = t || p.clientHeight, (this || _global).state.dropdown.visible) {
          if ("text" == w ? (n = (s = function () {
            const t = document.getSelection();
            if (t.rangeCount) {
              const e = t.getRangeAt(0),
                i = e.startContainer,
                s = e.startOffset;
              let a, n;
              if (s > 0) return n = document.createRange(), n.setStart(i, s - 1), n.setEnd(i, s), a = n.getBoundingClientRect(), {
                left: a.right,
                top: a.top,
                bottom: a.bottom
              };
              if (i.getBoundingClientRect) return i.getBoundingClientRect();
            }
            return {
              left: -9999,
              top: -9999
            };
          }()).bottom, a = s.top, o = s.left, r = "auto") : (l = function (t) {
            var e = 0,
              i = 0;
            for (t = t.parentNode; t && t != f;) e += t.offsetTop || 0, i += t.offsetLeft || 0, t = t.parentNode;
            return {
              top: e,
              left: i
            };
          }(i), s = b.getBoundingClientRect(), a = m ? -1 : s.top - l.top, n = (m ? s.height : s.bottom - l.top) - 1, o = m ? -1 : s.left - l.left, r = s.width + "px"), !u) {
            let t = function () {
              for (var t = 0, i = e.appendTarget.parentNode; i;) t += i.scrollTop || 0, i = i.parentNode;
              return t;
            }();
            a += t, n += t;
          }
          a = Math.floor(a), n = Math.ceil(n), h = ((d = e.placeAbove ?? T - s.bottom < t) ? a : n) + v, g = `left: ${o + (c && s.width || 0) + window.pageXOffset}px;`, p.style.cssText = `${g}; top: ${h}px; min-width: ${r}; max-width: ${r}`, p.setAttribute("placement", d ? "top" : "bottom"), p.setAttribute("position", w);
        }
      }
    },
    events: {
      binding() {
        let t = !(arguments.length > 0 && void 0 !== arguments[0]) || arguments[0];
        var e = (this || _global).dropdown.events.callbacks,
          i = (this || _global).listeners.dropdown = (this || _global).listeners.dropdown || {
            position: (this || _global).dropdown.position.bind(this || _global, null),
            onKeyDown: e.onKeyDown.bind(this || _global),
            onMouseOver: e.onMouseOver.bind(this || _global),
            onMouseLeave: e.onMouseLeave.bind(this || _global),
            onClick: e.onClick.bind(this || _global),
            onScroll: e.onScroll.bind(this || _global)
          },
          s = t ? "addEventListener" : "removeEventListener";
        "manual" != (this || _global).settings.dropdown.position && (document[s]("scroll", i.position, !0), window[s]("resize", i.position), window[s]("keydown", i.onKeyDown)), (this || _global).DOM.dropdown[s]("mouseover", i.onMouseOver), (this || _global).DOM.dropdown[s]("mouseleave", i.onMouseLeave), (this || _global).DOM.dropdown[s]("mousedown", i.onClick), (this || _global).DOM.dropdown.content[s]("scroll", i.onScroll);
      },
      callbacks: {
        onKeyDown(t) {
          if ((this || _global).state.hasFocus && !(this || _global).state.composing) {
            var e = (this || _global).settings,
              i = (this || _global).DOM.dropdown.querySelector(e.classNames.dropdownItemActiveSelector),
              s = (this || _global).dropdown.getSuggestionDataByNode(i),
              a = "mix" == e.mode;
            e.hooks.beforeKeyDown(t, {
              tagify: this || _global
            }).then(n => {
              switch (console.log(t.key), t.key) {
                case "ArrowDown":
                case "ArrowUp":
                case "Down":
                case "Up":
                  t.preventDefault();
                  var o = (this || _global).dropdown.getAllSuggestionsRefs(),
                    r = "ArrowUp" == t.key || "Up" == t.key;
                  i && (i = (this || _global).dropdown.getNextOrPrevOption(i, !r)), i && i.matches(e.classNames.dropdownItemSelector) || (i = o[r ? o.length - 1 : 0]), (this || _global).dropdown.highlightOption(i, !0);
                  break;
                case "Escape":
                case "Esc":
                  (this || _global).dropdown.hide();
                  break;
                case "ArrowRight":
                  if ((this || _global).state.actions.ArrowLeft) return;
                case "Tab":
                  {
                    let n = !e.autoComplete.rightKey || !e.autoComplete.tabKey;
                    if (!a && i && n && !(this || _global).state.editing) {
                      t.preventDefault();
                      var l = (this || _global).dropdown.getMappedValue(s);
                      return (this || _global).input.autocomplete.set.call(this || _global, l), !1;
                    }
                    return !0;
                  }
                case "Enter":
                  t.preventDefault(), e.hooks.suggestionClick(t, {
                    tagify: this || _global,
                    tagData: s,
                    suggestionElm: i
                  }).then(() => {
                    if (i) return (this || _global).dropdown.selectOption(i), i = (this || _global).dropdown.getNextOrPrevOption(i, !r), void (this || _global).dropdown.highlightOption(i);
                    (this || _global).dropdown.hide(), a || this.addTags((this || _global).state.inputText.trim(), !0);
                  }).catch(t => t);
                  break;
                case "Backspace":
                  {
                    if (a || (this || _global).state.editing.scope) return;
                    const t = (this || _global).input.raw.call(this || _global);
                    "" != t && 8203 != t.charCodeAt(0) || (!0 === e.backspace ? this.removeTags() : "edit" == e.backspace && setTimeout((this || _global).editTag.bind(this || _global), 0));
                  }
              }
            });
          }
        },
        onMouseOver(t) {
          var e = t.target.closest((this || _global).settings.classNames.dropdownItemSelector);
          (this || _global).dropdown.highlightOption(e);
        },
        onMouseLeave(t) {
          (this || _global).dropdown.highlightOption();
        },
        onClick(t) {
          if (0 == t.button && t.target != (this || _global).DOM.dropdown && t.target != (this || _global).DOM.dropdown.content) {
            var e = t.target.closest((this || _global).settings.classNames.dropdownItemSelector),
              i = (this || _global).dropdown.getSuggestionDataByNode(e);
            (this || _global).state.actions.selectOption = !0, setTimeout(() => (this || _global).state.actions.selectOption = !1, 50), (this || _global).settings.hooks.suggestionClick(t, {
              tagify: this || _global,
              tagData: i,
              suggestionElm: e
            }).then(() => {
              e ? (this || _global).dropdown.selectOption(e, t) : (this || _global).dropdown.hide();
            }).catch(t => console.warn(t));
          }
        },
        onScroll(t) {
          var e = t.target,
            i = e.scrollTop / (e.scrollHeight - e.parentNode.clientHeight) * 100;
          this.trigger("dropdown:scroll", {
            percentage: Math.round(i)
          });
        }
      }
    },
    getSuggestionDataByNode(t) {
      var e = t && t.getAttribute("value");
      return (this || _global).suggestedListItems.find(t => t.value == e) || null;
    },
    getNextOrPrevOption(t) {
      let e = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
      var i = (this || _global).dropdown.getAllSuggestionsRefs(),
        s = i.findIndex(e => e === t);
      return e ? i[s + 1] : i[s - 1];
    },
    highlightOption(t, e) {
      var i,
        s = (this || _global).settings.classNames.dropdownItemActive;
      if ((this || _global).state.ddItemElm && ((this || _global).state.ddItemElm.classList.remove(s), (this || _global).state.ddItemElm.removeAttribute("aria-selected")), !t) return (this || _global).state.ddItemData = null, (this || _global).state.ddItemElm = null, void (this || _global).input.autocomplete.suggest.call(this || _global);
      i = (this || _global).dropdown.getSuggestionDataByNode(t), (this || _global).state.ddItemData = i, (this || _global).state.ddItemElm = t, t.classList.add(s), t.setAttribute("aria-selected", !0), e && (t.parentNode.scrollTop = t.clientHeight + t.offsetTop - t.parentNode.clientHeight), (this || _global).settings.autoComplete && ((this || _global).input.autocomplete.suggest.call(this || _global, i), (this || _global).dropdown.position());
    },
    selectOption(t, e) {
      var i = (this || _global).settings,
        s = i.dropdown,
        a = s.clearOnSelect,
        n = s.closeOnSelect;
      if (!t) return this.addTags((this || _global).state.inputText, !0), void (n && (this || _global).dropdown.hide());
      e = e || {};
      var o = t.getAttribute("value"),
        r = "noMatch" == o,
        l = (this || _global).suggestedListItems.find(t => (t.value ?? t) == o);
      if (this.trigger("dropdown:select", {
        data: l,
        elm: t,
        event: e
      }), o && (l || r)) {
        if ((this || _global).state.editing) {
          let t = this.normalizeTags([l])[0];
          l = i.transformTag.call(this || _global, t) || t, this.onEditTagDone(null, g({
            __isValid: !0
          }, l));
        } else this["mix" == i.mode ? "addMixTags" : "addTags"]([l || (this || _global).input.raw.call(this || _global)], a);
        (this || _global).DOM.input.parentNode && (setTimeout(() => {
          (this || _global).DOM.input.focus(), this.toggleFocusClass(!0);
        }), n && setTimeout((this || _global).dropdown.hide.bind(this || _global)), t.addEventListener("transitionend", () => {
          (this || _global).dropdown.fillHeaderFooter(), setTimeout(() => t.remove(), 100);
        }, {
          once: !0
        }), t.classList.add((this || _global).settings.classNames.dropdownItemHidden));
      } else n && setTimeout((this || _global).dropdown.hide.bind(this || _global));
    },
    selectAll(t) {
      (this || _global).suggestedListItems.length = 0, (this || _global).dropdown.hide(), (this || _global).dropdown.filterListItems("");
      var e = (this || _global).dropdown.filterListItems("");
      return t || (e = (this || _global).state.dropdown.suggestions), this.addTags(e, !0), this || _global;
    },
    filterListItems(t, e) {
      var i,
        s,
        a,
        n,
        o,
        r = (this || _global).settings,
        l = r.dropdown,
        d = (e = e || {}, []),
        g = [],
        p = r.whitelist,
        u = l.maxItems >= 0 ? l.maxItems : 1 / 0,
        m = l.searchKeys,
        v = 0;
      if (!(t = "select" == r.mode && (this || _global).value.length && (this || _global).value[0][r.tagTextProp] == t ? "" : t) || !m.length) return d = l.includeSelectedTags ? p : p.filter(t => !this.isTagDuplicate(h(t) ? t.value : t)), (this || _global).state.dropdown.suggestions = d, d.slice(0, u);
      function f(t, e) {
        return e.toLowerCase().split(" ").every(e => t.includes(e.toLowerCase()));
      }
      for (o = l.caseSensitive ? "" + t : ("" + t).toLowerCase(); v < p.length; v++) {
        let t, r;
        i = p[v] instanceof Object ? p[v] : {
          value: p[v]
        };
        let u = !Object.keys(i).some(t => m.includes(t)) ? ["value"] : m;
        l.fuzzySearch && !e.exact ? (a = u.reduce((t, e) => t + " " + (i[e] || ""), "").toLowerCase().trim(), l.accentedSearch && (a = c(a), o = c(o)), t = 0 == a.indexOf(o), r = a === o, s = f(a, o)) : (t = !0, s = u.some(t => {
          var s = "" + (i[t] || "");
          return l.accentedSearch && (s = c(s), o = c(o)), l.caseSensitive || (s = s.toLowerCase()), r = s === o, e.exact ? s === o : 0 == s.indexOf(o);
        })), n = !l.includeSelectedTags && this.isTagDuplicate(h(i) ? i.value : i), s && !n && (r && t ? g.push(i) : "startsWith" == l.sortby && t ? d.unshift(i) : d.push(i));
      }
      return (this || _global).state.dropdown.suggestions = g.concat(d), "function" == typeof l.sortby ? l.sortby(g.concat(d), o) : g.concat(d).slice(0, u);
    },
    getMappedValue(t) {
      var e = (this || _global).settings.dropdown.mapValueTo;
      return e ? "function" == typeof e ? e(t) : t[e] || t.value : t.value;
    },
    createListHTML(t) {
      return g([], t).map((t, i) => {
        "string" != typeof t && "number" != typeof t || (t = {
          value: t
        });
        var s = (this || _global).dropdown.getMappedValue(t);
        return s = "string" == typeof s && (this || _global).settings.dropdown.escapeHTML ? d(s) : s, (this || _global).settings.templates.dropdownItem.apply(this || _global, [e(e({}, t), {}, {
          mappedValue: s
        }), this || _global]);
      }).join("");
    }
  };
  const D = "@yaireo/tagify/";
  var M,
    I = {
      empty: "empty",
      exceed: "number of tags exceeded",
      pattern: "pattern mismatch",
      duplicate: "already exists",
      notAllowed: "not allowed"
    },
    N = {
      wrapper: (t, e) => `<tags class="${e.classNames.namespace} ${e.mode ? `${e.classNames[e.mode + "Mode"]}` : ""} ${t.className}"\n                    ${e.readonly ? "readonly" : ""}\n                    ${e.disabled ? "disabled" : ""}\n                    ${e.required ? "required" : ""}\n                    ${"select" === e.mode ? "spellcheck='false'" : ""}\n                    tabIndex="-1">\n            <span ${!e.readonly && e.userInput ? "contenteditable" : ""} tabIndex="0" data-placeholder="${e.placeholder || "&#8203;"}" aria-placeholder="${e.placeholder || ""}"\n                class="${e.classNames.input}"\n                role="textbox"\n                aria-autocomplete="both"\n                aria-multiline="${"mix" == e.mode}"></span>\n                &#8203;\n        </tags>`,
      tag(t, e) {
        let i = e.settings;
        return `<tag title="${t.title || t.value}"\n                    contenteditable='false'\n                    spellcheck='false'\n                    tabIndex="${i.a11y.focusableTags ? 0 : -1}"\n                    class="${i.classNames.tag} ${t.class || ""}"\n                    ${this.getAttributes(t)}>\n            <x title='' class="${i.classNames.tagX}" role='button' aria-label='remove tag'></x>\n            <div>\n                <span class="${i.classNames.tagText}">${t[i.tagTextProp] || t.value}</span>\n            </div>\n        </tag>`;
      },
      dropdown(t) {
        var e = t.dropdown;
        return `<div class="${"manual" == e.position ? "" : t.classNames.dropdown} ${e.classname}" role="listbox" aria-labelledby="dropdown" dir="${e.RTL ? "rtl" : ""}">\n                    <div data-selector='tagify-suggestions-wrapper' class="${t.classNames.dropdownWrapper}"></div>\n                </div>`;
      },
      dropdownContent(t) {
        var e = (this || _global).settings.templates,
          i = (this || _global).state.dropdown.suggestions;
        return `\n            ${e.dropdownHeader.call(this || _global, i)}\n            ${t}\n            ${e.dropdownFooter.call(this || _global, i)}\n        `;
      },
      dropdownItem(t) {
        return `<div ${this.getAttributes(t)}\n                    class='${(this || _global).settings.classNames.dropdownItem} ${t.class || ""}'\n                    tabindex="0"\n                    role="option">${t.mappedValue || t.value}</div>`;
      },
      dropdownHeader(t) {
        return `<header data-selector='tagify-suggestions-header' class="${(this || _global).settings.classNames.dropdownHeader}"></header>`;
      },
      dropdownFooter(t) {
        var e = t.length - (this || _global).settings.dropdown.maxItems;
        return e > 0 ? `<footer data-selector='tagify-suggestions-footer' class="${(this || _global).settings.classNames.dropdownFooter}">\n                ${e} more items. Refine your search.\n            </footer>` : "";
      },
      dropdownItemNoMatch: null
    };
  var E = {
    customBinding() {
      (this || _global).customEventsList.forEach(t => {
        this.on(t, (this || _global).settings.callbacks[t]);
      });
    },
    binding() {
      let t = !(arguments.length > 0 && void 0 !== arguments[0]) || arguments[0];
      var e,
        i = (this || _global).events.callbacks,
        s = t ? "addEventListener" : "removeEventListener";
      if (!(this || _global).state.mainEvents || !t) {
        for (var a in (this || _global).state.mainEvents = t, t && !(this || _global).listeners.main && ((this || _global).events.bindGlobal.call(this || _global), (this || _global).settings.isJQueryPlugin && jQuery((this || _global).DOM.originalInput).on("tagify.removeAllTags", (this || _global).removeAllTags.bind(this || _global))), e = (this || _global).listeners.main = (this || _global).listeners.main || {
          focus: ["input", i.onFocusBlur.bind(this || _global)],
          keydown: ["input", i.onKeydown.bind(this || _global)],
          click: ["scope", i.onClickScope.bind(this || _global)],
          dblclick: ["scope", i.onDoubleClickScope.bind(this || _global)],
          paste: ["input", i.onPaste.bind(this || _global)],
          drop: ["input", i.onDrop.bind(this || _global)],
          compositionstart: ["input", i.onCompositionStart.bind(this || _global)],
          compositionend: ["input", i.onCompositionEnd.bind(this || _global)]
        }) (this || _global).DOM[e[a][0]][s](a, e[a][1]);
        clearInterval((this || _global).listeners.main.originalInputValueObserverInterval), (this || _global).listeners.main.originalInputValueObserverInterval = setInterval(i.observeOriginalInputValue.bind(this || _global), 500);
        var n = (this || _global).listeners.main.inputMutationObserver || new MutationObserver(i.onInputDOMChange.bind(this || _global));
        n.disconnect(), "mix" == (this || _global).settings.mode && n.observe((this || _global).DOM.input, {
          childList: !0
        });
      }
    },
    bindGlobal(t) {
      var e,
        i = (this || _global).events.callbacks,
        s = t ? "removeEventListener" : "addEventListener";
      if ((this || _global).listeners && (t || !(this || _global).listeners.global)) for (e of ((this || _global).listeners.global = (this || _global).listeners.global || [{
        type: (this || _global).isIE ? "keydown" : "input",
        target: (this || _global).DOM.input,
        cb: i[(this || _global).isIE ? "onInputIE" : "onInput"].bind(this || _global)
      }, {
        type: "keydown",
        target: window,
        cb: i.onWindowKeyDown.bind(this || _global)
      }, {
        type: "blur",
        target: (this || _global).DOM.input,
        cb: i.onFocusBlur.bind(this || _global)
      }, {
        type: "click",
        target: document,
        cb: i.onClickAnywhere.bind(this || _global)
      }], (this || _global).listeners.global)) e.target[s](e.type, e.cb);
    },
    unbindGlobal() {
      (this || _global).events.bindGlobal.call(this || _global, !0);
    },
    callbacks: {
      onFocusBlur(t) {
        var e = (this || _global).settings,
          i = t.target ? this.trim(t.target.textContent) : "",
          s = (this || _global).value?.[0]?.[e.tagTextProp],
          a = t.type,
          n = e.dropdown.enabled >= 0,
          o = {
            relatedTarget: t.relatedTarget
          },
          r = (this || _global).state.actions.selectOption && (n || !e.dropdown.closeOnSelect),
          l = (this || _global).state.actions.addNew && n;
        if ("blur" == a) {
          if (t.relatedTarget === (this || _global).DOM.scope) return (this || _global).dropdown.hide(), void (this || _global).DOM.input.focus();
          this.postUpdate(), e.onChangeAfterBlur && this.triggerChangeEvent();
        }
        if (!r && !l) if ((this || _global).state.hasFocus = "focus" == a && +new Date(), this.toggleFocusClass((this || _global).state.hasFocus), "mix" != e.mode) {
          if ("focus" == a) return this.trigger("focus", o), void (0 !== e.dropdown.enabled && e.userInput || (this || _global).state.dropdown.visible || (this || _global).dropdown.show((this || _global).value.length ? "" : void 0));
          "blur" == a && (this.trigger("blur", o), this.loading(!1), "select" == e.mode && s === i && (i = ""), i && !(this || _global).state.actions.selectOption && e.addTagOnBlur && e.addTagOn.includes("blur") && this.addTags(i, !0)), (this || _global).DOM.input.removeAttribute("style"), (this || _global).dropdown.hide();
        } else "focus" == a ? this.trigger("focus", o) : "blur" == t.type && (this.trigger("blur", o), this.loading(!1), (this || _global).dropdown.hide(), (this || _global).state.dropdown.visible = void 0, this.setStateSelection());
      },
      onCompositionStart(t) {
        (this || _global).state.composing = !0;
      },
      onCompositionEnd(t) {
        (this || _global).state.composing = !1;
      },
      onWindowKeyDown(t) {
        var e,
          i = document.activeElement,
          s = v.call(this || _global, i) && (this || _global).DOM.scope.contains(document.activeElement),
          a = s && i.hasAttribute("readonly");
        if (s && !a) switch (e = i.nextElementSibling, t.key) {
          case "Backspace":
            (this || _global).settings.readonly || (this.removeTags(i), (e || (this || _global).DOM.input).focus());
            break;
          case "Enter":
            setTimeout((this || _global).editTag.bind(this || _global), 0, i);
        }
      },
      onKeydown(t) {
        var e = (this || _global).settings;
        if (!(this || _global).state.composing && e.userInput) {
          "select" == e.mode && e.enforceWhitelist && (this || _global).value.length && "Tab" != t.key && t.preventDefault();
          var i = this.trim(t.target.textContent);
          this.trigger("keydown", {
            event: t
          }), e.hooks.beforeKeyDown(t, {
            tagify: this || _global
          }).then(s => {
            if ("mix" == e.mode) {
              switch (t.key) {
                case "Left":
                case "ArrowLeft":
                  (this || _global).state.actions.ArrowLeft = !0;
                  break;
                case "Delete":
                case "Backspace":
                  if ((this || _global).state.editing) return;
                  var a = document.getSelection(),
                    n = "Delete" == t.key && a.anchorOffset == (a.anchorNode.length || 0),
                    r = a.anchorNode.previousSibling,
                    d = 1 == a.anchorNode.nodeType || !a.anchorOffset && r && 1 == r.nodeType && a.anchorNode.previousSibling;
                  o((this || _global).DOM.input.innerHTML);
                  var h,
                    g,
                    p,
                    c = this.getTagElms(),
                    m = 1 === a.anchorNode.length && a.anchorNode.nodeValue == String.fromCharCode(8203);
                  if ("edit" == e.backspace && d) return h = 1 == a.anchorNode.nodeType ? null : a.anchorNode.previousElementSibling, setTimeout((this || _global).editTag.bind(this || _global), 0, h), void t.preventDefault();
                  if (u() && d instanceof Element) return p = l(d), d.hasAttribute("readonly") || d.remove(), (this || _global).DOM.input.focus(), void setTimeout(() => {
                    w(p), (this || _global).DOM.input.click();
                  });
                  if ("BR" == a.anchorNode.nodeName) return;
                  if ((n || d) && 1 == a.anchorNode.nodeType ? g = 0 == a.anchorOffset ? n ? c[0] : null : c[Math.min(c.length, a.anchorOffset) - 1] : n ? g = a.anchorNode.nextElementSibling : d instanceof Element && (g = d), 3 == a.anchorNode.nodeType && !a.anchorNode.nodeValue && a.anchorNode.previousElementSibling && t.preventDefault(), (d || n) && !e.backspace) return void t.preventDefault();
                  if ("Range" != a.type && !a.anchorOffset && a.anchorNode == (this || _global).DOM.input && "Delete" != t.key) return void t.preventDefault();
                  if ("Range" != a.type && g && g.hasAttribute("readonly")) return void w(l(g));
                  "Delete" == t.key && m && T(a.anchorNode.nextSibling) && this.removeTags(a.anchorNode.nextSibling), clearTimeout(M), M = setTimeout(() => {
                    var t = document.getSelection();
                    o((this || _global).DOM.input.innerHTML), !n && t.anchorNode.previousSibling, (this || _global).value = [].map.call(c, (t, e) => {
                      var i = T(t);
                      if (t.parentNode || i.readonly) return i;
                      this.trigger("remove", {
                        tag: t,
                        index: e,
                        data: i
                      });
                    }).filter(t => t);
                  }, 20);
              }
              return !0;
            }
            var v = "manual" == e.dropdown.position;
            switch (t.key) {
              case "Backspace":
                "select" == e.mode && e.enforceWhitelist && (this || _global).value.length ? this.removeTags() : (this || _global).state.dropdown.visible && "manual" != e.dropdown.position || "" != t.target.textContent && 8203 != i.charCodeAt(0) || (!0 === e.backspace ? this.removeTags() : "edit" == e.backspace && setTimeout((this || _global).editTag.bind(this || _global), 0));
                break;
              case "Esc":
              case "Escape":
                if ((this || _global).state.dropdown.visible) return;
                t.target.blur();
                break;
              case "Down":
              case "ArrowDown":
                (this || _global).state.dropdown.visible || (this || _global).dropdown.show();
                break;
              case "ArrowRight":
                {
                  let t = (this || _global).state.inputSuggestion || (this || _global).state.ddItemData;
                  if (t && e.autoComplete.rightKey) return void this.addTags([t], !0);
                  break;
                }
              case "Tab":
                {
                  let s = "select" == e.mode;
                  if (!i || s) return !0;
                  t.preventDefault();
                }
              case "Enter":
                if ((this || _global).state.dropdown.visible && !v) return;
                t.preventDefault(), setTimeout(() => {
                  (this || _global).state.dropdown.visible && !v || (this || _global).state.actions.selectOption || !e.addTagOn.includes(t.key.toLowerCase()) || this.addTags(i, !0);
                });
            }
          }).catch(t => t);
        }
      },
      onInput(t) {
        this.postUpdate();
        var e = (this || _global).settings;
        if ("mix" == e.mode) return (this || _global).events.callbacks.onMixTagsInput.call(this || _global, t);
        var i = (this || _global).input.normalize.call(this || _global, void 0, {
            trim: !1
          }),
          s = i.length >= e.dropdown.enabled,
          a = {
            value: i,
            inputElm: (this || _global).DOM.input
          },
          n = this.validateTag({
            value: i
          });
        "select" == e.mode && this.toggleScopeValidation(n), a.isValid = n, (this || _global).state.inputText != i && ((this || _global).input.set.call(this || _global, i, !1), -1 != i.search(e.delimiters) ? this.addTags(i) && (this || _global).input.set.call(this || _global) : e.dropdown.enabled >= 0 && (this || _global).dropdown[s ? "show" : "hide"](i), this.trigger("input", a));
      },
      onMixTagsInput(t) {
        var e,
          i,
          s,
          a,
          n,
          o,
          r,
          l,
          d = (this || _global).settings,
          h = (this || _global).value.length,
          p = this.getTagElms(),
          c = document.createDocumentFragment(),
          m = window.getSelection().getRangeAt(0),
          v = [].map.call(p, t => T(t).value);
        if ("deleteContentBackward" == t.inputType && u() && (this || _global).events.callbacks.onKeydown.call(this || _global, {
          target: t.target,
          key: "Backspace"
        }), b(this.getTagElms()), (this || _global).value.slice().forEach(t => {
          t.readonly && !v.includes(t.value) && c.appendChild(this.createTagElem(t));
        }), c.childNodes.length && (m.insertNode(c), this.setRangeAtStartEnd(!1, c.lastChild)), p.length != h) return (this || _global).value = [].map.call(this.getTagElms(), t => T(t)), void this.update({
          withoutChangeEvent: !0
        });
        if (this.hasMaxTags()) return !0;
        if (window.getSelection && (o = window.getSelection()).rangeCount > 0 && 3 == o.anchorNode.nodeType) {
          if ((m = o.getRangeAt(0).cloneRange()).collapse(!0), m.setStart(o.focusNode, 0), s = (e = m.toString().slice(0, m.endOffset)).split(d.pattern).length - 1, (i = e.match(d.pattern)) && (a = e.slice(e.lastIndexOf(i[i.length - 1]))), a) {
            if ((this || _global).state.actions.ArrowLeft = !1, (this || _global).state.tag = {
              prefix: a.match(d.pattern)[0],
              value: a.replace(d.pattern, "")
            }, (this || _global).state.tag.baseOffset = o.baseOffset - (this || _global).state.tag.value.length, l = (this || _global).state.tag.value.match(d.delimiters)) return (this || _global).state.tag.value = (this || _global).state.tag.value.replace(d.delimiters, ""), (this || _global).state.tag.delimiters = l[0], this.addTags((this || _global).state.tag.value, d.dropdown.clearOnSelect), void (this || _global).dropdown.hide();
            n = (this || _global).state.tag.value.length >= d.dropdown.enabled;
            try {
              r = (r = (this || _global).state.flaggedTags[(this || _global).state.tag.baseOffset]).prefix == (this || _global).state.tag.prefix && r.value[0] == (this || _global).state.tag.value[0], (this || _global).state.flaggedTags[(this || _global).state.tag.baseOffset] && !(this || _global).state.tag.value && delete (this || _global).state.flaggedTags[(this || _global).state.tag.baseOffset];
            } catch (t) {}
            (r || s < (this || _global).state.mixMode.matchedPatternCount) && (n = !1);
          } else (this || _global).state.flaggedTags = {};
          (this || _global).state.mixMode.matchedPatternCount = s;
        }
        setTimeout(() => {
          this.update({
            withoutChangeEvent: !0
          }), this.trigger("input", g({}, (this || _global).state.tag, {
            textContent: (this || _global).DOM.input.textContent
          })), (this || _global).state.tag && (this || _global).dropdown[n ? "show" : "hide"]((this || _global).state.tag.value);
        }, 10);
      },
      onInputIE(t) {
        var e = this || _global;
        setTimeout(function () {
          e.events.callbacks.onInput.call(e, t);
        });
      },
      observeOriginalInputValue() {
        (this || _global).DOM.originalInput.parentNode || this.destroy(), (this || _global).DOM.originalInput.value != (this || _global).DOM.originalInput.tagifyValue && this.loadOriginalValues();
      },
      onClickAnywhere(t) {
        t.target == (this || _global).DOM.scope || (this || _global).DOM.scope.contains(t.target) || (this.toggleFocusClass(!1), (this || _global).state.hasFocus = !1);
      },
      onClickScope(t) {
        var e = (this || _global).settings,
          i = t.target.closest("." + e.classNames.tag),
          s = +new Date() - (this || _global).state.hasFocus;
        if (t.target != (this || _global).DOM.scope) {
          if (!t.target.classList.contains(e.classNames.tagX)) return i && !(this || _global).state.editing ? (this.trigger("click", {
            tag: i,
            index: this.getNodeIndex(i),
            data: T(i),
            event: t
          }), void (1 !== e.editTags && 1 !== e.editTags.clicks && "select" != e.mode || (this || _global).events.callbacks.onDoubleClickScope.call(this || _global, t))) : void (t.target == (this || _global).DOM.input && ("mix" == e.mode && this.fixFirefoxLastTagNoCaret(), s > 500) ? (this || _global).state.dropdown.visible ? (this || _global).dropdown.hide() : 0 === e.dropdown.enabled && "mix" != e.mode && (this || _global).dropdown.show((this || _global).value.length ? "" : void 0) : "select" != e.mode || 0 !== e.dropdown.enabled || (this || _global).state.dropdown.visible || (this || _global).dropdown.show());
          this.removeTags(t.target.parentNode);
        } else (this || _global).DOM.input.focus();
      },
      onPaste(t) {
        t.preventDefault();
        var e,
          i,
          s = (this || _global).settings;
        if ("select" == s.mode && s.enforceWhitelist || !s.userInput) return !1;
        s.readonly || (e = t.clipboardData || window.clipboardData, i = e.getData("Text"), s.hooks.beforePaste(t, {
          tagify: this || _global,
          pastedText: i,
          clipboardData: e
        }).then(e => {
          void 0 === e && (e = i), e && (this.injectAtCaret(e, window.getSelection().getRangeAt(0)), "mix" == (this || _global).settings.mode ? (this || _global).events.callbacks.onMixTagsInput.call(this || _global, t) : (this || _global).settings.pasteAsTags ? this.addTags((this || _global).state.inputText + e, !0) : ((this || _global).state.inputText = e, (this || _global).dropdown.show(e)));
        }).catch(t => t));
      },
      onDrop(t) {
        t.preventDefault();
      },
      onEditTagInput(t, e) {
        var i = t.closest("." + (this || _global).settings.classNames.tag),
          s = this.getNodeIndex(i),
          a = T(i),
          n = (this || _global).input.normalize.call(this || _global, t),
          o = {
            [(this || _global).settings.tagTextProp]: n,
            __tagId: a.__tagId
          },
          r = this.validateTag(o);
        this.editTagChangeDetected(g(a, o)) || !0 !== t.originalIsValid || (r = !0), i.classList.toggle((this || _global).settings.classNames.tagInvalid, !0 !== r), a.__isValid = r, i.title = !0 === r ? a.title || a.value : r, n.length >= (this || _global).settings.dropdown.enabled && ((this || _global).state.editing && ((this || _global).state.editing.value = n), (this || _global).dropdown.show(n)), this.trigger("edit:input", {
          tag: i,
          index: s,
          data: g({}, (this || _global).value[s], {
            newValue: n
          }),
          event: e
        });
      },
      onEditTagPaste(t, e) {
        var i = (e.clipboardData || window.clipboardData).getData("Text");
        e.preventDefault();
        var s = f(i);
        this.setRangeAtStartEnd(!1, s);
      },
      onEditTagClick(t, e) {
        (this || _global).events.callbacks.onClickScope.call(this || _global, e);
      },
      onEditTagFocus(t) {
        (this || _global).state.editing = {
          scope: t,
          input: t.querySelector("[contenteditable]")
        };
      },
      onEditTagBlur(t, e) {
        if (v.call(this || _global, e.relatedTarget) && e.relatedTarget.contains(e.target)) (this || _global).dropdown.hide();else if ((this || _global).state.editing && ((this || _global).state.hasFocus || this.toggleFocusClass(), (this || _global).DOM.scope.contains(t))) {
          var i,
            s,
            a = (this || _global).settings,
            n = t.closest("." + a.classNames.tag),
            o = T(n),
            r = (this || _global).input.normalize.call(this || _global, t),
            l = {
              [a.tagTextProp]: r,
              __tagId: o.__tagId
            },
            d = o.__originalData,
            h = this.editTagChangeDetected(g(o, l)),
            p = this.validateTag(l);
          if (r) {
            if (h) {
              if (i = this.hasMaxTags(), s = g({}, d, {
                [a.tagTextProp]: this.trim(r),
                __isValid: p
              }), a.transformTag.call(this || _global, s, d), !0 !== (p = (!i || !0 === d.__isValid) && this.validateTag(s))) {
                if (this.trigger("invalid", {
                  data: s,
                  tag: n,
                  message: p
                }), a.editTags.keepInvalid) return;
                a.keepInvalidTags ? s.__isValid = p : s = d;
              } else a.keepInvalidTags && (delete s.title, delete s["aria-invalid"], delete s.class);
              this.onEditTagDone(n, s);
            } else this.onEditTagDone(n, d);
          } else this.onEditTagDone(n);
        }
      },
      onEditTagkeydown(t, e) {
        if (!(this || _global).state.composing) switch (this.trigger("edit:keydown", {
          event: t
        }), t.key) {
          case "Esc":
          case "Escape":
            (this || _global).state.editing = !1, !!e.__tagifyTagData.__originalData.value ? e.parentNode.replaceChild(e.__tagifyTagData.__originalHTML, e) : e.remove();
            break;
          case "Enter":
          case "Tab":
            t.preventDefault();
            setTimeout(t.target.blur, 100);
        }
      },
      onDoubleClickScope(t) {
        var e,
          i,
          s = t.target.closest("." + (this || _global).settings.classNames.tag),
          a = T(s),
          n = (this || _global).settings;
        s && n.userInput && !1 !== a.editable && (e = s.classList.contains((this || _global).settings.classNames.tagEditing), i = s.hasAttribute("readonly"), n.readonly || e || i || !(this || _global).settings.editTags || this.editTag(s), this.toggleFocusClass(!0), "select" != n.mode && this.trigger("dblclick", {
          tag: s,
          index: this.getNodeIndex(s),
          data: T(s)
        }));
      },
      onInputDOMChange(t) {
        t.forEach(t => {
          t.addedNodes.forEach(t => {
            if ("<div><br></div>" == t.outerHTML) t.replaceWith(document.createElement("br"));else if (1 == t.nodeType && t.querySelector((this || _global).settings.classNames.tagSelector)) {
              let e = document.createTextNode("");
              3 == t.childNodes[0].nodeType && "BR" != t.previousSibling.nodeName && (e = document.createTextNode("\n")), t.replaceWith(e, ...[...t.childNodes].slice(0, -1)), w(e);
            } else if (v.call(this || _global, t)) if (3 != t.previousSibling?.nodeType || t.previousSibling.textContent || t.previousSibling.remove(), t.previousSibling && "BR" == t.previousSibling.nodeName) {
              t.previousSibling.replaceWith("\n\u200B");
              let e = t.nextSibling,
                i = "";
              for (; e;) i += e.textContent, e = e.nextSibling;
              i.trim() && w(t.previousSibling);
            } else t.previousSibling && !T(t.previousSibling) || t.before("\u200B");
          }), t.removedNodes.forEach(t => {
            t && "BR" == t.nodeName && v.call(this || _global, e) && (this.removeTags(e), this.fixFirefoxLastTagNoCaret());
          });
        });
        var e = (this || _global).DOM.input.lastChild;
        e && "" == e.nodeValue && e.remove(), e && "BR" == e.nodeName || (this || _global).DOM.input.appendChild(document.createElement("br"));
      }
    }
  };
  function _(t, e) {
    if (!t) {
      console.warn("Tagify:", "input element not found", t);
      const e = new Proxy(this || _global, {
        get: () => () => e
      });
      return e;
    }
    if (t.__tagify) return console.warn("Tagify: ", "input element is already Tagified - Same instance is returned.", t), t.__tagify;
    var i;
    g(this || _global, function (t) {
      var e = document.createTextNode("");
      function i(t, i, s) {
        s && i.split(/\s+/g).forEach(i => e[t + "EventListener"].call(e, i, s));
      }
      return {
        off(t, e) {
          return i("remove", t, e), this || _global;
        },
        on(t, e) {
          return e && "function" == typeof e && i("add", t, e), this || _global;
        },
        trigger(i, s, a) {
          var n;
          if (a = a || {
            cloneData: !0
          }, i) if (t.settings.isJQueryPlugin) "remove" == i && (i = "removeTag"), jQuery(t.DOM.originalInput).triggerHandler(i, [s]);else {
            try {
              var o = "object" == typeof s ? s : {
                value: s
              };
              if ((o = a.cloneData ? g({}, o) : o).tagify = this || _global, s.event && (o.event = this.cloneEvent(s.event)), s instanceof Object) for (var r in s) s[r] instanceof HTMLElement && (o[r] = s[r]);
              n = new CustomEvent(i, {
                detail: o
              });
            } catch (t) {
              console.warn(t);
            }
            e.dispatchEvent(n);
          }
        }
      };
    }(this || _global)), (this || _global).isFirefox = /firefox|fxios/i.test(navigator.userAgent) && !/seamonkey/i.test(navigator.userAgent), (this || _global).isIE = window.document.documentMode, e = e || {}, (this || _global).getPersistedData = (i = e.id, t => {
      let e,
        s = "/" + t;
      if (1 == localStorage.getItem(D + i + "/v", 1)) try {
        e = JSON.parse(localStorage[D + i + s]);
      } catch (t) {}
      return e;
    }), (this || _global).setPersistedData = (t => t ? (localStorage.setItem(D + t + "/v", 1), (e, i) => {
      let s = "/" + i,
        a = JSON.stringify(e);
      e && i && (localStorage.setItem(D + t + s, a), dispatchEvent(new Event("storage")));
    }) : () => {})(e.id), (this || _global).clearPersistedData = (t => e => {
      const i = D + "/" + t + "/";
      if (e) localStorage.removeItem(i + e);else for (let t in localStorage) t.includes(i) && localStorage.removeItem(t);
    })(e.id), this.applySettings(t, e), (this || _global).state = {
      inputText: "",
      editing: !1,
      composing: !1,
      actions: {},
      mixMode: {},
      dropdown: {},
      flaggedTags: {}
    }, (this || _global).value = [], (this || _global).listeners = {}, (this || _global).DOM = {}, this.build(t), x.call(this || _global), this.getCSSVars(), this.loadOriginalValues(), (this || _global).events.customBinding.call(this || _global), (this || _global).events.binding.call(this || _global), t.autofocus && (this || _global).DOM.input.focus(), t.__tagify = this || _global;
  }
  return _.prototype = {
    _dropdown: O,
    placeCaretAfterNode: w,
    getSetTagData: T,
    helpers: {
      sameStr: s,
      removeCollectionProp: a,
      omit: n,
      isObject: h,
      parseHTML: r,
      escapeHTML: d,
      extend: g,
      concatWithoutDups: p,
      getUID: m,
      isNodeTag: v
    },
    customEventsList: ["change", "add", "remove", "invalid", "input", "click", "keydown", "focus", "blur", "edit:input", "edit:beforeUpdate", "edit:updated", "edit:start", "edit:keydown", "dropdown:show", "dropdown:hide", "dropdown:select", "dropdown:updated", "dropdown:noMatch", "dropdown:scroll"],
    dataProps: ["__isValid", "__removed", "__originalData", "__originalHTML", "__tagId"],
    trim(t) {
      return (this || _global).settings.trim && t && "string" == typeof t ? t.trim() : t;
    },
    parseHTML: r,
    templates: N,
    parseTemplate(t, e) {
      return r((t = (this || _global).settings.templates[t] || t).apply(this || _global, e));
    },
    set whitelist(t) {
      const e = t && Array.isArray(t);
      (this || _global).settings.whitelist = e ? t : [], this.setPersistedData(e ? t : [], "whitelist");
    },
    get whitelist() {
      return (this || _global).settings.whitelist;
    },
    generateClassSelectors(t) {
      for (let e in t) {
        let i = e;
        Object.defineProperty(t, i + "Selector", {
          get() {
            return "." + (this || _global)[i].split(" ")[0];
          }
        });
      }
    },
    applySettings(t, i) {
      y.templates = (this || _global).templates;
      var s = g({}, y, "mix" == i.mode ? {
          dropdown: {
            position: "text"
          }
        } : {}),
        a = (this || _global).settings = g({}, s, i);
      if (a.disabled = t.hasAttribute("disabled"), a.readonly = a.readonly || t.hasAttribute("readonly"), a.placeholder = d(t.getAttribute("placeholder") || a.placeholder || ""), a.required = t.hasAttribute("required"), this.generateClassSelectors(a.classNames), void 0 === a.dropdown.includeSelectedTags && (a.dropdown.includeSelectedTags = a.duplicates), (this || _global).isIE && (a.autoComplete = !1), ["whitelist", "blacklist"].forEach(e => {
        var i = t.getAttribute("data-" + e);
        i && (i = i.split(a.delimiters)) instanceof Array && (a[e] = i);
      }), "autoComplete" in i && !h(i.autoComplete) && (a.autoComplete = y.autoComplete, a.autoComplete.enabled = i.autoComplete), "mix" == a.mode && (a.pattern = a.pattern || /@/, a.autoComplete.rightKey = !0, a.delimiters = i.delimiters || null, a.tagTextProp && !a.dropdown.searchKeys.includes(a.tagTextProp) && a.dropdown.searchKeys.push(a.tagTextProp)), t.pattern) try {
        a.pattern = new RegExp(t.pattern);
      } catch (t) {}
      if (a.delimiters) {
        a._delimiters = a.delimiters;
        try {
          a.delimiters = new RegExp((this || _global).settings.delimiters, "g");
        } catch (t) {}
      }
      a.disabled && (a.userInput = !1), (this || _global).TEXTS = e(e({}, I), a.texts || {}), ("select" != a.mode || i.dropdown?.enabled) && a.userInput || (a.dropdown.enabled = 0), a.dropdown.appendTarget = i.dropdown?.appendTarget || document.body;
      let n = this.getPersistedData("whitelist");
      Array.isArray(n) && ((this || _global).whitelist = Array.isArray(a.whitelist) ? p(a.whitelist, n) : n);
    },
    getAttributes(t) {
      var e,
        i = this.getCustomAttributes(t),
        s = "";
      for (e in i) s += " " + e + (void 0 !== t[e] ? `="${i[e]}"` : "");
      return s;
    },
    getCustomAttributes(t) {
      if (!h(t)) return "";
      var e,
        i = {};
      for (e in t) "__" != e.slice(0, 2) && "class" != e && t.hasOwnProperty(e) && void 0 !== t[e] && (i[e] = d(t[e]));
      return i;
    },
    setStateSelection() {
      var t = window.getSelection(),
        e = {
          anchorOffset: t.anchorOffset,
          anchorNode: t.anchorNode,
          range: t.getRangeAt && t.rangeCount && t.getRangeAt(0)
        };
      return (this || _global).state.selection = e, e;
    },
    getCSSVars() {
      var t = getComputedStyle((this || _global).DOM.scope, null);
      var e;
      (this || _global).CSSVars = {
        tagHideTransition: (t => {
          let e = t.value;
          return "s" == t.unit ? 1000 * e : e;
        })(function (t) {
          if (!t) return {};
          var e = (t = t.trim().split(" ")[0]).split(/\d+/g).filter(t => t).pop().trim();
          return {
            value: +t.split(e).filter(t => t)[0].trim(),
            unit: e
          };
        }((e = "tag-hide-transition", t.getPropertyValue("--" + e))))
      };
    },
    build(t) {
      var e = (this || _global).DOM;
      (this || _global).settings.mixMode.integrated ? (e.originalInput = null, e.scope = t, e.input = t) : (e.originalInput = t, e.originalInput_tabIndex = t.tabIndex, e.scope = this.parseTemplate("wrapper", [t, (this || _global).settings]), e.input = e.scope.querySelector((this || _global).settings.classNames.inputSelector), t.parentNode.insertBefore(e.scope, t), t.tabIndex = -1);
    },
    destroy() {
      (this || _global).events.unbindGlobal.call(this || _global), (this || _global).DOM.scope.parentNode.removeChild((this || _global).DOM.scope), (this || _global).DOM.originalInput.tabIndex = (this || _global).DOM.originalInput_tabIndex, delete (this || _global).DOM.originalInput.__tagify, (this || _global).dropdown.hide(!0), clearTimeout((this || _global).dropdownHide__bindEventsTimeout), clearInterval((this || _global).listeners.main.originalInputValueObserverInterval);
    },
    loadOriginalValues(t) {
      var e,
        i = (this || _global).settings;
      if ((this || _global).state.blockChangeEvent = !0, void 0 === t) {
        const e = this.getPersistedData("value");
        t = e && !(this || _global).DOM.originalInput.value ? e : i.mixMode.integrated ? (this || _global).DOM.input.textContent : (this || _global).DOM.originalInput.value;
      }
      if (this.removeAllTags(), t) {
        if ("mix" == i.mode) this.parseMixTags(t), (e = (this || _global).DOM.input.lastChild) && "BR" == e.tagName || (this || _global).DOM.input.insertAdjacentHTML("beforeend", "<br>");else {
          try {
            JSON.parse(t) instanceof Array && (t = JSON.parse(t));
          } catch (t) {}
          this.addTags(t, !0).forEach(t => t && t.classList.add(i.classNames.tagNoAnimation));
        }
      } else this.postUpdate();
      (this || _global).state.lastOriginalValueReported = i.mixMode.integrated ? "" : (this || _global).DOM.originalInput.value;
    },
    cloneEvent(t) {
      var e = {};
      for (var i in t) "path" != i && (e[i] = t[i]);
      return e;
    },
    loading(t) {
      return (this || _global).state.isLoading = t, (this || _global).DOM.scope.classList[t ? "add" : "remove"]((this || _global).settings.classNames.scopeLoading), this || _global;
    },
    tagLoading(t, e) {
      return t && t.classList[e ? "add" : "remove"]((this || _global).settings.classNames.tagLoading), this || _global;
    },
    toggleClass(t, e) {
      "string" == typeof t && (this || _global).DOM.scope.classList.toggle(t, e);
    },
    toggleScopeValidation(t) {
      var e = !0 === t || void 0 === t;
      !(this || _global).settings.required && t && t === (this || _global).TEXTS.empty && (e = !0), this.toggleClass((this || _global).settings.classNames.tagInvalid, !e), (this || _global).DOM.scope.title = e ? "" : t;
    },
    toggleFocusClass(t) {
      this.toggleClass((this || _global).settings.classNames.focus, !!t);
    },
    triggerChangeEvent: function () {
      if (!(this || _global).settings.mixMode.integrated) {
        var t = (this || _global).DOM.originalInput,
          e = (this || _global).state.lastOriginalValueReported !== t.value,
          i = new CustomEvent("change", {
            bubbles: !0
          });
        e && ((this || _global).state.lastOriginalValueReported = t.value, i.simulated = !0, t._valueTracker && t._valueTracker.setValue(Math.random()), t.dispatchEvent(i), this.trigger("change", (this || _global).state.lastOriginalValueReported), t.value = (this || _global).state.lastOriginalValueReported);
      }
    },
    events: E,
    fixFirefoxLastTagNoCaret() {},
    setRangeAtStartEnd(t, e) {
      if (e) {
        t = "number" == typeof t ? t : !!t, e = e.lastChild || e;
        var i = document.getSelection();
        if (i.focusNode instanceof Element && !(this || _global).DOM.input.contains(i.focusNode)) return !0;
        try {
          i.rangeCount >= 1 && ["Start", "End"].forEach(s => i.getRangeAt(0)["set" + s](e, t || e.length));
        } catch (t) {
          console.warn("Tagify: ", t);
        }
      }
    },
    insertAfterTag(t, e) {
      if (e = e || (this || _global).settings.mixMode.insertAfterTag, t && t.parentNode && e) return e = "string" == typeof e ? document.createTextNode(e) : e, t.parentNode.insertBefore(e, t.nextSibling), e;
    },
    editTagChangeDetected(t) {
      var e = t.__originalData;
      for (var i in e) if (!(this || _global).dataProps.includes(i) && t[i] != e[i]) return !0;
      return !1;
    },
    getTagTextNode(t) {
      return t.querySelector((this || _global).settings.classNames.tagTextSelector);
    },
    setTagTextNode(t, e) {
      this.getTagTextNode(t).innerHTML = d(e);
    },
    editTag(t, e) {
      t = t || this.getLastTag(), e = e || {}, (this || _global).dropdown.hide();
      var i = (this || _global).settings,
        s = this.getTagTextNode(t),
        a = this.getNodeIndex(t),
        n = T(t),
        o = (this || _global).events.callbacks,
        r = !0;
      if (s) {
        if (!(n instanceof Object && "editable" in n) || n.editable) return n = T(t, {
          __originalData: g({}, n),
          __originalHTML: t.cloneNode(!0)
        }), T(n.__originalHTML, n.__originalData), s.setAttribute("contenteditable", !0), t.classList.add(i.classNames.tagEditing), s.addEventListener("click", o.onEditTagClick.bind(this || _global, t)), s.addEventListener("focus", o.onEditTagFocus.bind(this || _global, t)), s.addEventListener("blur", o.onEditTagBlur.bind(this || _global, this.getTagTextNode(t))), s.addEventListener("input", o.onEditTagInput.bind(this || _global, s)), s.addEventListener("paste", o.onEditTagPaste.bind(this || _global, s)), s.addEventListener("keydown", e => o.onEditTagkeydown.call(this || _global, e, t)), s.addEventListener("compositionstart", o.onCompositionStart.bind(this || _global)), s.addEventListener("compositionend", o.onCompositionEnd.bind(this || _global)), e.skipValidation || (r = this.editTagToggleValidity(t)), s.originalIsValid = r, this.trigger("edit:start", {
          tag: t,
          index: a,
          data: n,
          isValid: r
        }), s.focus(), this.setRangeAtStartEnd(!1, s), 0 === i.dropdown.enabled && (this || _global).dropdown.show(), (this || _global).state.hasFocus = !0, this || _global;
      } else console.warn("Cannot find element in Tag template: .", i.classNames.tagTextSelector);
    },
    editTagToggleValidity(t, e) {
      var i;
      if (e = e || T(t)) return (i = !("__isValid" in e) || !0 === e.__isValid) || this.removeTagsFromValue(t), this.update(), t.classList.toggle((this || _global).settings.classNames.tagNotAllowed, !i), e.__isValid = i, e.__isValid;
      console.warn("tag has no data: ", t, e);
    },
    onEditTagDone(t, e) {
      t = t || (this || _global).state.editing.scope, e = e || {};
      var i,
        s = {
          tag: t,
          index: this.getNodeIndex(t),
          previousData: T(t),
          data: e
        },
        a = (this || _global).settings;
      this.trigger("edit:beforeUpdate", s, {
        cloneData: !1
      }), (this || _global).state.editing = !1, delete e.__originalData, delete e.__originalHTML, t && "" !== ("" !== (i = e[a.tagTextProp]) ? i.trim?.() : a.tagTextProp in e ? void 0 : e.value) ? (t = this.replaceTag(t, e), this.editTagToggleValidity(t, e), a.a11y.focusableTags ? t.focus() : w(t)) : t && this.removeTags(t), this.trigger("edit:updated", s), (this || _global).dropdown.hide(), (this || _global).settings.keepInvalidTags && this.reCheckInvalidTags();
    },
    replaceTag(t, e) {
      e && "" !== e.value && void 0 !== e.value || (e = t.__tagifyTagData), e.__isValid && 1 != e.__isValid && g(e, this.getInvalidTagAttrs(e, e.__isValid));
      var i = this.createTagElem(e);
      return t.parentNode.replaceChild(i, t), this.updateValueByDOMTags(), i;
    },
    updateValueByDOMTags() {
      (this || _global).value.length = 0, [].forEach.call(this.getTagElms(), t => {
        t.classList.contains((this || _global).settings.classNames.tagNotAllowed.split(" ")[0]) || (this || _global).value.push(T(t));
      }), this.update();
    },
    injectAtCaret(t, e) {
      if (!(e = e || (this || _global).state.selection?.range) && t) return this.appendMixTags(t), this || _global;
      let i = f(t, e);
      return this.setRangeAtStartEnd(!1, i), this.updateValueByDOMTags(), this.update(), this || _global;
    },
    input: {
      set() {
        let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
          e = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
        var i = (this || _global).settings.dropdown.closeOnSelect;
        (this || _global).state.inputText = t, e && ((this || _global).DOM.input.innerHTML = d("" + t)), !t && i && (this || _global).dropdown.hide.bind(this || _global), (this || _global).input.autocomplete.suggest.call(this || _global), (this || _global).input.validate.call(this || _global);
      },
      raw() {
        return (this || _global).DOM.input.textContent;
      },
      validate() {
        var t = !(this || _global).state.inputText || !0 === this.validateTag({
          value: (this || _global).state.inputText
        });
        return (this || _global).DOM.input.classList.toggle((this || _global).settings.classNames.inputInvalid, !t), t;
      },
      normalize(t, e) {
        var i = t || (this || _global).DOM.input,
          s = [];
        i.childNodes.forEach(t => 3 == t.nodeType && s.push(t.nodeValue)), s = s.join("\n");
        try {
          s = s.replace(/(?:\r\n|\r|\n)/g, (this || _global).settings.delimiters.source.charAt(0));
        } catch (t) {}
        return s = s.replace(/\s/g, " "), e?.trim ? this.trim(s) : s;
      },
      autocomplete: {
        suggest(t) {
          if ((this || _global).settings.autoComplete.enabled) {
            "string" == typeof (t = t || {
              value: ""
            }) && (t = {
              value: t
            });
            var e = (this || _global).dropdown.getMappedValue(t);
            if ("number" != typeof e) {
              var i = e.substr(0, (this || _global).state.inputText.length).toLowerCase(),
                s = e.substring((this || _global).state.inputText.length);
              e && (this || _global).state.inputText && i == (this || _global).state.inputText.toLowerCase() ? ((this || _global).DOM.input.setAttribute("data-suggest", s), (this || _global).state.inputSuggestion = t) : ((this || _global).DOM.input.removeAttribute("data-suggest"), delete (this || _global).state.inputSuggestion);
            }
          }
        },
        set(t) {
          var e = (this || _global).DOM.input.getAttribute("data-suggest"),
            i = t || (e ? (this || _global).state.inputText + e : null);
          return !!i && ("mix" == (this || _global).settings.mode ? this.replaceTextWithNode(document.createTextNode((this || _global).state.tag.prefix + i)) : ((this || _global).input.set.call(this || _global, i), this.setRangeAtStartEnd(!1, (this || _global).DOM.input)), (this || _global).input.autocomplete.suggest.call(this || _global), (this || _global).dropdown.hide(), !0);
        }
      }
    },
    getTagIdx(t) {
      return (this || _global).value.findIndex(e => e.__tagId == (t || {}).__tagId);
    },
    getNodeIndex(t) {
      var e = 0;
      if (t) for (; t = t.previousElementSibling;) e++;
      return e;
    },
    getTagElms() {
      for (var t = arguments.length, e = new Array(t), i = 0; i < t; i++) e[i] = arguments[i];
      var s = "." + [...(this || _global).settings.classNames.tag.split(" "), ...e].join(".");
      return [].slice.call((this || _global).DOM.scope.querySelectorAll(s));
    },
    getLastTag() {
      var t = (this || _global).DOM.scope.querySelectorAll(`${(this || _global).settings.classNames.tagSelector}:not(.${(this || _global).settings.classNames.tagHide}):not([readonly])`);
      return t[t.length - 1];
    },
    isTagDuplicate(t, e, i) {
      var a = 0;
      if ("select" == (this || _global).settings.mode) return !1;
      for (let n of (this || _global).value) {
        s(this.trim("" + t), n.value, e) && i != n.__tagId && a++;
      }
      return a;
    },
    getTagIndexByValue(t) {
      var e = [],
        i = (this || _global).settings.dropdown.caseSensitive;
      return this.getTagElms().forEach((a, n) => {
        a.__tagifyTagData && s(this.trim(a.__tagifyTagData.value), t, i) && e.push(n);
      }), e;
    },
    getTagElmByValue(t) {
      var e = this.getTagIndexByValue(t)[0];
      return this.getTagElms()[e];
    },
    flashTag(t) {
      t && (t.classList.add((this || _global).settings.classNames.tagFlash), setTimeout(() => {
        t.classList.remove((this || _global).settings.classNames.tagFlash);
      }, 100));
    },
    isTagBlacklisted(t) {
      return t = this.trim(t.toLowerCase()), (this || _global).settings.blacklist.filter(e => ("" + e).toLowerCase() == t).length;
    },
    isTagWhitelisted(t) {
      return !!this.getWhitelistItem(t);
    },
    getWhitelistItem(t, e, i) {
      e = e || "value";
      var a,
        n = (this || _global).settings;
      return (i = i || n.whitelist).some(i => {
        var o = "string" == typeof i ? i : i[e] || i.value;
        if (s(o, t, n.dropdown.caseSensitive, n.trim)) return a = "string" == typeof i ? {
          value: i
        } : i, !0;
      }), a || "value" != e || "value" == n.tagTextProp || (a = this.getWhitelistItem(t, n.tagTextProp, i)), a;
    },
    validateTag(t) {
      var e = (this || _global).settings,
        i = "value" in t ? "value" : e.tagTextProp,
        s = this.trim(t[i] + "");
      return (t[i] + "").trim() ? "mix" != e.mode && e.pattern && e.pattern instanceof RegExp && !e.pattern.test(s) ? (this || _global).TEXTS.pattern : !e.duplicates && this.isTagDuplicate(s, e.dropdown.caseSensitive, t.__tagId) ? (this || _global).TEXTS.duplicate : this.isTagBlacklisted(s) || e.enforceWhitelist && !this.isTagWhitelisted(s) ? (this || _global).TEXTS.notAllowed : !e.validate || e.validate(t) : (this || _global).TEXTS.empty;
    },
    getInvalidTagAttrs(t, e) {
      return {
        "aria-invalid": !0,
        class: `${t.class || ""} ${(this || _global).settings.classNames.tagNotAllowed}`.trim(),
        title: e
      };
    },
    hasMaxTags() {
      return (this || _global).value.length >= (this || _global).settings.maxTags && (this || _global).TEXTS.exceed;
    },
    setReadonly(t, e) {
      var i = (this || _global).settings;
      document.activeElement.blur(), i[e || "readonly"] = t, (this || _global).DOM.scope[(t ? "set" : "remove") + "Attribute"](e || "readonly", !0), (this || _global).settings.userInput = !0, this.setContentEditable(!t);
    },
    setContentEditable(t) {
      (this || _global).settings.userInput && ((this || _global).DOM.input.contentEditable = t, (this || _global).DOM.input.tabIndex = t ? 0 : -1);
    },
    setDisabled(t) {
      this.setReadonly(t, "disabled");
    },
    normalizeTags(t) {
      var e = (this || _global).settings,
        i = e.whitelist,
        s = e.delimiters,
        a = e.mode,
        n = e.tagTextProp,
        o = [],
        r = !!i && i[0] instanceof Object,
        l = Array.isArray(t),
        d = l && t[0].value,
        h = t => (t + "").split(s).filter(t => t).map(t => ({
          [n]: this.trim(t),
          value: this.trim(t)
        }));
      if ("number" == typeof t && (t = t.toString()), "string" == typeof t) {
        if (!t.trim()) return [];
        t = h(t);
      } else l && (t = [].concat(...t.map(t => null != t.value ? t : h(t))));
      return r && !d && (t.forEach(t => {
        var e = o.map(t => t.value),
          i = (this || _global).dropdown.filterListItems.call(this || _global, t[n], {
            exact: !0
          });
        (this || _global).settings.duplicates || (i = i.filter(t => !e.includes(t.value)));
        var s = i.length > 1 ? this.getWhitelistItem(t[n], n, i) : i[0];
        s && s instanceof Object ? o.push(s) : "mix" != a && (null == t.value && (t.value = t[n]), o.push(t));
      }), o.length && (t = o)), t;
    },
    parseMixTags(t) {
      var e = (this || _global).settings,
        i = e.mixTagsInterpolator,
        s = e.duplicates,
        a = e.transformTag,
        n = e.enforceWhitelist,
        o = e.maxTags,
        r = e.tagTextProp,
        l = [];
      t = t.split(i[0]).map((t, e) => {
        var d,
          h,
          g,
          p = t.split(i[1]),
          c = p[0],
          u = l.length == o;
        try {
          if (c == +c) throw Error;
          h = JSON.parse(c);
        } catch (t) {
          h = this.normalizeTags(c)[0] || {
            value: c
          };
        }
        if (a.call(this || _global, h), u || !(p.length > 1) || n && !this.isTagWhitelisted(h.value) || !s && this.isTagDuplicate(h.value)) {
          if (t) return e ? i[0] + t : t;
        } else h[d = h[r] ? r : "value"] = this.trim(h[d]), g = this.createTagElem(h), l.push(h), g.classList.add((this || _global).settings.classNames.tagNoAnimation), p[0] = g.outerHTML, (this || _global).value.push(h);
        return p.join("");
      }).join(""), (this || _global).DOM.input.innerHTML = t, (this || _global).DOM.input.appendChild(document.createTextNode("")), (this || _global).DOM.input.normalize();
      var d = this.getTagElms();
      return d.forEach((t, e) => T(t, l[e])), this.update({
        withoutChangeEvent: !0
      }), b(d, (this || _global).state.hasFocus), t;
    },
    replaceTextWithNode(t, e) {
      if ((this || _global).state.tag || e) {
        e = e || (this || _global).state.tag.prefix + (this || _global).state.tag.value;
        var i,
          s,
          a = (this || _global).state.selection || window.getSelection(),
          n = a.anchorNode,
          o = (this || _global).state.tag.delimiters ? (this || _global).state.tag.delimiters.length : 0;
        return n.splitText(a.anchorOffset - o), -1 == (i = n.nodeValue.lastIndexOf(e)) ? !0 : (s = n.splitText(i), t && n.parentNode.replaceChild(t, s), !0);
      }
    },
    selectTag(t, e) {
      if (!(this || _global).settings.enforceWhitelist || this.isTagWhitelisted(e.value)) {
        (this || _global).state.actions.selectOption && setTimeout(() => this.setRangeAtStartEnd(!1, (this || _global).DOM.input));
        var i = this.getLastTag();
        return i ? this.replaceTag(i, e) : this.appendTag(t), (this || _global).value[0] = e, this.update(), this.trigger("add", {
          tag: t,
          data: e
        }), [t];
      }
    },
    addEmptyTag(t) {
      var e = g({
          value: ""
        }, t || {}),
        i = this.createTagElem(e);
      T(i, e), this.appendTag(i), this.editTag(i, {
        skipValidation: !0
      }), this.toggleFocusClass(!0);
    },
    addTags(t, e, i) {
      var s = [],
        a = (this || _global).settings,
        n = [],
        o = document.createDocumentFragment();
      if (i = i || a.skipInvalid, !t || 0 == t.length) return s;
      switch (t = this.normalizeTags(t), a.mode) {
        case "mix":
          return this.addMixTags(t);
        case "select":
          e = !1, this.removeAllTags();
      }
      return (this || _global).DOM.input.removeAttribute("style"), t.forEach(t => {
        var e,
          r = {},
          l = Object.assign({}, t, {
            value: t.value + ""
          });
        if (t = Object.assign({}, l), a.transformTag.call(this || _global, t), t.__isValid = this.hasMaxTags() || this.validateTag(t), !0 !== t.__isValid) {
          if (i) return;
          if (g(r, this.getInvalidTagAttrs(t, t.__isValid), {
            __preInvalidData: l
          }), t.__isValid == (this || _global).TEXTS.duplicate && this.flashTag(this.getTagElmByValue(t.value)), !a.createInvalidTags) return void n.push(t.value);
        }
        if ("readonly" in t && (t.readonly ? r["aria-readonly"] = !0 : delete t.readonly), e = this.createTagElem(t, r), s.push(e), "select" == a.mode) return this.selectTag(e, t);
        o.appendChild(e), t.__isValid && !0 === t.__isValid ? ((this || _global).value.push(t), this.trigger("add", {
          tag: e,
          index: (this || _global).value.length - 1,
          data: t
        })) : (this.trigger("invalid", {
          data: t,
          index: (this || _global).value.length,
          tag: e,
          message: t.__isValid
        }), a.keepInvalidTags || setTimeout(() => this.removeTags(e, !0), 1000)), (this || _global).dropdown.position();
      }), this.appendTag(o), this.update(), t.length && e && ((this || _global).input.set.call(this || _global, a.createInvalidTags ? "" : n.join(a._delimiters)), this.setRangeAtStartEnd(!1, (this || _global).DOM.input)), a.dropdown.enabled && (this || _global).dropdown.refilter(), s;
    },
    addMixTags(t) {
      if ((t = this.normalizeTags(t))[0].prefix || (this || _global).state.tag) return this.prefixedTextToTag(t[0]);
      var e = document.createDocumentFragment();
      return t.forEach(t => {
        var i = this.createTagElem(t);
        e.appendChild(i);
      }), this.appendMixTags(e), e;
    },
    appendMixTags(t) {
      var e = !!(this || _global).state.selection;
      e ? this.injectAtCaret(t) : ((this || _global).DOM.input.focus(), (e = this.setStateSelection()).range.setStart((this || _global).DOM.input, e.range.endOffset), e.range.setEnd((this || _global).DOM.input, e.range.endOffset), (this || _global).DOM.input.appendChild(t), this.updateValueByDOMTags(), this.update());
    },
    prefixedTextToTag(t) {
      var e,
        i = (this || _global).settings,
        s = (this || _global).state.tag.delimiters;
      if (i.transformTag.call(this || _global, t), t.prefix = t.prefix || (this || _global).state.tag ? (this || _global).state.tag.prefix : (i.pattern.source || i.pattern)[0], e = this.createTagElem(t), this.replaceTextWithNode(e) || (this || _global).DOM.input.appendChild(e), setTimeout(() => e.classList.add((this || _global).settings.classNames.tagNoAnimation), 300), (this || _global).value.push(t), this.update(), !s) {
        var a = this.insertAfterTag(e) || e;
        setTimeout(w, 0, a);
      }
      return (this || _global).state.tag = null, this.trigger("add", g({}, {
        tag: e
      }, {
        data: t
      })), e;
    },
    appendTag(t) {
      var e = (this || _global).DOM,
        i = e.input;
      e.scope.insertBefore(t, i);
    },
    createTagElem(t, i) {
      t.__tagId = m();
      var s,
        a = g({}, t, e({
          value: d(t.value + "")
        }, i));
      return function (t) {
        for (var e, i = document.createNodeIterator(t, NodeFilter.SHOW_TEXT, null, !1); e = i.nextNode();) e.textContent.trim() || e.parentNode.removeChild(e);
      }(s = this.parseTemplate("tag", [a, this || _global])), T(s, t), s;
    },
    reCheckInvalidTags() {
      var t = (this || _global).settings;
      this.getTagElms(t.classNames.tagNotAllowed).forEach((e, i) => {
        var s = T(e),
          a = this.hasMaxTags(),
          n = this.validateTag(s),
          o = !0 === n && !a;
        if ("select" == t.mode && this.toggleScopeValidation(n), o) return s = s.__preInvalidData ? s.__preInvalidData : {
          value: s.value
        }, this.replaceTag(e, s);
        e.title = a || n;
      });
    },
    removeTags(t, e, i) {
      var s,
        a = (this || _global).settings;
      if (t = t && t instanceof HTMLElement ? [t] : t instanceof Array ? t : t ? [t] : [this.getLastTag()], s = t.reduce((t, e) => {
        e && "string" == typeof e && (e = this.getTagElmByValue(e));
        var i = T(e);
        return e && i && !i.readonly && t.push({
          node: e,
          idx: this.getTagIdx(i),
          data: T(e, {
            __removed: !0
          })
        }), t;
      }, []), i = "number" == typeof i ? i : (this || _global).CSSVars.tagHideTransition, "select" == a.mode && (i = 0, (this || _global).input.set.call(this || _global)), 1 == s.length && "select" != a.mode && s[0].node.classList.contains(a.classNames.tagNotAllowed) && (e = !0), s.length) return a.hooks.beforeRemoveTag(s, {
        tagify: this || _global
      }).then(() => {
        function t(t) {
          t.node.parentNode && (t.node.parentNode.removeChild(t.node), e ? a.keepInvalidTags && this.trigger("remove", {
            tag: t.node,
            index: t.idx
          }) : (this.trigger("remove", {
            tag: t.node,
            index: t.idx,
            data: t.data
          }), (this || _global).dropdown.refilter(), (this || _global).dropdown.position(), (this || _global).DOM.input.normalize(), a.keepInvalidTags && this.reCheckInvalidTags()));
        }
        i && i > 10 && 1 == s.length ? function (e) {
          e.node.style.width = parseFloat(window.getComputedStyle(e.node).width) + "px", document.body.clientTop, e.node.classList.add(a.classNames.tagHide), setTimeout(t.bind(this || _global), i, e);
        }.call(this || _global, s[0]) : s.forEach(t.bind(this || _global)), e || (this.removeTagsFromValue(s.map(t => t.node)), this.update(), "select" == a.mode && this.setContentEditable(!0));
      }).catch(t => {});
    },
    removeTagsFromDOM() {
      [].slice.call(this.getTagElms()).forEach(t => t.parentNode.removeChild(t));
    },
    removeTagsFromValue(t) {
      (t = Array.isArray(t) ? t : [t]).forEach(t => {
        var e = T(t),
          i = this.getTagIdx(e);
        i > -1 && (this || _global).value.splice(i, 1);
      });
    },
    removeAllTags(t) {
      t = t || {}, (this || _global).value = [], "mix" == (this || _global).settings.mode ? (this || _global).DOM.input.innerHTML = "" : this.removeTagsFromDOM(), (this || _global).dropdown.refilter(), (this || _global).dropdown.position(), (this || _global).state.dropdown.visible && setTimeout(() => {
        (this || _global).DOM.input.focus();
      }), "select" == (this || _global).settings.mode && ((this || _global).input.set.call(this || _global), this.setContentEditable(!0)), this.update(t);
    },
    postUpdate() {
      (this || _global).state.blockChangeEvent = !1;
      var t = (this || _global).settings,
        e = t.classNames,
        i = "mix" == t.mode ? t.mixMode.integrated ? (this || _global).DOM.input.textContent : (this || _global).DOM.originalInput.value.trim() : (this || _global).value.length + (this || _global).input.raw.call(this || _global).length;
      this.toggleClass(e.hasMaxTags, (this || _global).value.length >= t.maxTags), this.toggleClass(e.hasNoTags, !(this || _global).value.length), this.toggleClass(e.empty, !i), "select" == t.mode && this.toggleScopeValidation((this || _global).value?.[0]?.__isValid);
    },
    setOriginalInputValue(t) {
      var e = (this || _global).DOM.originalInput;
      (this || _global).settings.mixMode.integrated || (e.value = t, e.tagifyValue = e.value, this.setPersistedData(t, "value"));
    },
    update(t) {
      clearTimeout((this || _global).debouncedUpdateTimeout), (this || _global).debouncedUpdateTimeout = setTimeout(function () {
        var e = this.getInputValue();
        this.setOriginalInputValue(e), (this || _global).settings.onChangeAfterBlur && (t || {}).withoutChangeEvent || (this || _global).state.blockChangeEvent || this.triggerChangeEvent();
        this.postUpdate();
      }.bind(this || _global), 100);
    },
    getInputValue() {
      var t = this.getCleanValue();
      return "mix" == (this || _global).settings.mode ? this.getMixedTagsAsString(t) : t.length ? (this || _global).settings.originalInputValueFormat ? (this || _global).settings.originalInputValueFormat(t) : JSON.stringify(t) : "";
    },
    getCleanValue(t) {
      return a(t || (this || _global).value, (this || _global).dataProps);
    },
    getMixedTagsAsString() {
      var t = "",
        e = this || _global,
        i = (this || _global).settings,
        s = i.originalInputValueFormat || JSON.stringify,
        a = i.mixTagsInterpolator;
      return function i(o) {
        o.childNodes.forEach(o => {
          if (1 == o.nodeType) {
            const r = T(o);
            if ("BR" == o.tagName && (t += "\r\n"), r && v.call(e, o)) {
              if (r.__removed) return;
              t += a[0] + s(n(r, e.dataProps)) + a[1];
            } else o.getAttribute("style") || ["B", "I", "U"].includes(o.tagName) ? t += o.textContent : "DIV" != o.tagName && "P" != o.tagName || (t += "\r\n", i(o));
          } else t += o.textContent;
        });
      }((this || _global).DOM.input), t;
    }
  }, _.prototype.removeTag = _.prototype.removeTags, _;
});
export default exports;